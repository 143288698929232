import {createAsyncThunk} from "@reduxjs/toolkit";
import {ApiManager} from "../../managers/api-manager";


export const fetchBacktests = createAsyncThunk(
  'backtests/fetchBacktests',
  async (_: void, {dispatch, getState}) => {

    const result = await ApiManager.fetchBacktests();
    return result.items;
  }
)
