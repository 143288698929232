
// import {H2} from "../../components/H2/H2";


import React from "react";
import './Home.scss'
import {useHistory} from "react-router";

export const Home = () => {

  // console.log('Home()');
  const history = useHistory();

  const didClickBacktesting = () => {
    history.push('/backtest')
  }

  const didClickSetupLibrary = () => {
    history.push('/setups')
  }

  return (
    <div className={'home-wrapper'}>

      <h2 className={'title'}>Andrew's Trading Tools</h2>

      <div className={'home-items'}>

        <div className={'home-item'} onClick={() => didClickBacktesting()}>
          <p>Backtesting</p>
        </div>

        <div className={'home-item'} onClick={() => didClickSetupLibrary()}>
          <p>Setup Library</p>
        </div>
      </div>

    </div>
  )


}
