import {createSlice} from "@reduxjs/toolkit";
import {ISetup} from "../../models/interfaces/i-setup";
import {fetchSetups} from "../thunks/fetch-setups-thunk";
import {fetchDetailedSetup} from "../thunks/fetch-detailed-setup-thunk";
import {ISetupInstance} from "../../models/interfaces/i-setup-instance";
import {createSetupInstance} from "../thunks/create-setup-instance-thunk";
import {fetchSetupInstances} from "../thunks/fetch-setup-instances-thunk";
import {deleteSetupInstanceThunk} from "../thunks/delete-setup-instance-thunk";

interface SetupsState {
  setups: Array<ISetup>
  detailedSetup?: ISetup
  setupInstances: Array<ISetupInstance>
  isFetchingSetups: boolean
  isFetchingDetailedSetup: boolean
  isFetchingSetupInstances: boolean
  isPostingSetupInstance: boolean
  detailedSetupFetchError: any
  setupsFetchError: any
  setupInstancesFetchError: any
  setupInstancePostError: any
}

const initialState: SetupsState = {
  setups: [],
  detailedSetup: undefined,
  setupInstances: [],
  isFetchingSetups: false,
  isFetchingDetailedSetup: false,
  isFetchingSetupInstances: false,
  isPostingSetupInstance: false,
  detailedSetupFetchError: undefined,
  setupsFetchError: undefined,
  setupInstancesFetchError: undefined,
  setupInstancePostError: undefined
}

export const setupsSlice = createSlice({
  name: 'setups',
  initialState,
  reducers: {
    clearSetupInstances: (state, action) => {
      state.setupInstances = [];
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchSetups.pending, (state, action) => {
      state.isFetchingSetups = true;
      state.setupsFetchError = undefined;
    });
    builder.addCase(fetchSetups.fulfilled, (state, action) => {
      state.isFetchingSetups = false;
      state.setups = action.payload;
    });
    builder.addCase(fetchSetups.rejected, (state, action) => {
      state.isFetchingSetups = false;
      state.setupsFetchError = action.payload;
    });
    builder.addCase(fetchDetailedSetup.pending, (state, action) => {
      state.isFetchingDetailedSetup = true;
      state.detailedSetupFetchError = undefined;
    });
    builder.addCase(fetchDetailedSetup.fulfilled, (state, action) => {
      state.isFetchingDetailedSetup = false;
      state.detailedSetup = action.payload;
    });
    builder.addCase(fetchDetailedSetup.rejected, (state, action) => {
      state.isFetchingDetailedSetup = false;
      state.detailedSetupFetchError = action.error;
    });
    builder.addCase(createSetupInstance.pending, (state, action) => {
      state.isPostingSetupInstance = true;
      state.setupInstancePostError = undefined;
    });
    builder.addCase(createSetupInstance.fulfilled, (state, action) => {
      state.isPostingSetupInstance = false;
      state.setupInstances.unshift(action.payload);
    });
    builder.addCase(createSetupInstance.rejected, (state, action) => {
      state.isPostingSetupInstance = false;
      state.setupInstancePostError = action.payload;
    });
    builder.addCase(fetchSetupInstances.pending, (state, action) => {
      state.isFetchingSetupInstances = true;
      state.setupInstancesFetchError = undefined;
    });
    builder.addCase(fetchSetupInstances.fulfilled, (state, action) => {
      state.isFetchingSetupInstances = false;
      const currentIds = state.setupInstances.map(instance => instance.id);
      action.payload.forEach(instance => {
        if (!currentIds.includes(instance.id)) {
          state.setupInstances.push(instance);
        }
      })
    });
    builder.addCase(fetchSetupInstances.rejected, (state, action) => {
      state.isFetchingSetupInstances = false;
      state.setupInstancesFetchError = action.error;
    });
    builder.addCase(deleteSetupInstanceThunk.fulfilled, (state, action) => {
      console.log('filtering: ', state.setupInstances.length, action.payload);
      state.setupInstances = state.setupInstances.filter(instance => instance.id !== action.payload);
      console.log('filtered down to ', state.setupInstances.length);
    })
  }
});
