import './Setups.scss'
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import React from "react";
import {SetupsListing} from "./setups-listing/SetupsListing";
import {Setup} from "./setup/Setup";


export const Setups = () => {
  let match = useRouteMatch()

  return (
    <div className={'setups-wrapper'}>

      <Switch>
        <Route path={`${match.path}/:setupId`} component={Setup} />
        <Route path={`${match.path}`} exact component={SetupsListing} />
      </Switch>


    </div>

  )
}
