import {createAsyncThunk} from "@reduxjs/toolkit";
import {ApiManager} from "../../managers/api-manager";


export const fetchBacktest = createAsyncThunk(
  'backtest/fetchBacktest',
  async (backtestId: number, {dispatch, getState}) => {

    console.log('fetchBacktest');
    const result = await ApiManager.fetchBacktest(backtestId);

    console.log('returning: ', result);
    return result;
  }
)
