import {Switch, useRouteMatch} from "react-router";
import React from "react";

import './Backtests.scss'
import {Route} from "react-router-dom";
import {Backtest} from "./backtest/Backtest";
import {BacktestListing} from "./backtest-listing/BacktestListing";
import {Position} from "../position/Position";

export const Backtests = () => {
  let match = useRouteMatch()

  return (

    <div className={'backtests-wrapper'}>


      <Switch>
        <Route path={`${match.path}/:backtestId/positions/:positionId`} component={Position} />
        <Route path={`${match.path}/:backtestId`} component={Backtest} />
        <Route path={`${match.path}`} exact component={BacktestListing} />
      </Switch>

    </div>

  )
}
