import {createAsyncThunk} from "@reduxjs/toolkit";
import {ApiManager} from "../../managers/api-manager";

export const deleteBacktestThunk = createAsyncThunk(
  'backtest/deleteBacktest',
  async (backtestId: number, {dispatch, getState}) => {
    console.log('deleteBacktest');
    await ApiManager.deleteBacktest(backtestId);
  }
)
