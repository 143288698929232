import React, {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroller";

import './BacktestListing.scss'
import {Link} from "react-router-dom";
import {useAppDispatch} from "../../../store";
import {useTypedSelector} from "../../../store/reducers";
import ActivityIndicator from "../../../components/ActivityIndicator";
import Table from "../../../components/Table";
import {formatPercent, renderDate} from "../../../util/formatting";
import {fetchBacktests} from "../../../store/thunks/fetch-backtests-thunk";
import {IBacktest} from "../../../models/interfaces/i-backtest";
import moment from "moment";
import Button from "../../../components/Button";
import {Icon} from "../../../components/Icon/Icon";
import {deleteBacktestThunk} from "../../../store/thunks/delete-backtest-thunk";

export const BacktestListing = () => {
  // console.log('BacktestListing()');
  const dispatch = useAppDispatch();

  const {backtests, isFetchingBacktests} = useTypedSelector(state => state.backtest);
  const [atEnd, setAtEnd] = useState(true);
  const [isInfiniteScrolling, setIsInfiniteScrolling] = useState(false);

  // useEffect(() => {
  //   dispatch(fetchBacktests());
  // }, []) // eslint-disable-line

  useEffect(() => {
    const initialize = async () => {
      if(!isFetchingBacktests) {
        dispatch(fetchBacktests());
      } else {
        setAtEnd(false);
      }
    };
    initialize();
  }, []); // eslint-disable-line

  const handleInfiniteScroll = async () => {
    console.log('handleInfiniteScroll');
    if(isFetchingBacktests || isInfiniteScrolling || atEnd) {
      return;
    }

    try {
      setIsInfiniteScrolling(true);
      let res = await dispatch(fetchBacktests());
      setTimeout(() => {
        console.log('res: ', res);
        setAtEnd(true);
        setIsInfiniteScrolling(false);
      }, 200);

    } catch(err) {
      console.log('Home, handleInfiniteScroll err', err);
      setAtEnd(true);
      setIsInfiniteScrolling(false);
    }
  };

  const deleteBacktest = (backtestId: number) => {
    dispatch(deleteBacktestThunk(backtestId))
  }

  // const navigateToBacktest = (backtestId: number) => {
  //   history.push('/backtests/' + backtestId);
  // }

  type BacktestRow = {row: {original: IBacktest}}

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Strategy',
        accessor: 'strategy',
        Cell: ({row: {original}}: BacktestRow) => {
          return (
            <Link to={'/backtest/' + original.id}>{original.strategy} - Run {original.id}</Link>
            // <Button
            //   className="c-activity-stream-list__type-button"
            //   noStyles
            //   onClick={() => navigateToBacktest(original.id)}
            // >
            //   {original.id}
            // </Button>
          );
        }
      },
      {
        Header: 'Date Ran',
        accessor: 'createdAt',
        Cell: ({row: {original}}: BacktestRow) => {
          // console.log('rendering: ', original)
          return (
            <p>{renderDate(original.createdAt)}</p>
          );
        }
      },
      {
        Header: 'Time Range',
        accessor: 'timeRange',
        Cell: ({row: {original}}: BacktestRow) => {
          return (
            <p>{moment(original.start).format('MMM DD, YYYY')} - {moment(original.end).format('MMM DD, YYYY')} ({original.stats.timeRangeDays} days)</p>
          )
        }
      },
      {
        Header: 'CAGR',
        accessor: 'cagr',
        Cell: ({row: {original}}: BacktestRow) => {
          return (
            <p>{formatPercent(original.stats.cagr || 0)}</p>
          )
        }
      },
      {
        Header: 'Max Drawdown',
        accessor: 'maxDrawdown',
        Cell: ({row: {original}}: BacktestRow) => {
          return (
            <p>{formatPercent(original.stats.maxDrawdown || 0)}</p>
          )
        }
      },
      {
        Header: 'Utils',
        accessor: 'utils',
        Cell: ({row: {original}}: BacktestRow) => {
          return (
            <>
              <Button size={"small"} onClick={() => deleteBacktest(original.id)}>
                <Icon type={'trash'} size={'lg'}/>
              </Button>
            </>
          )
        }
      }
    ]
  }, []); // eslint-disable-line

  return (

    <div className={'backtests-wrapper'}>

      {/*<header className="App-header">*/}
      {/*  <p>*/}
      {/*    Edit <code>src/App.tsx</code> and save to reload.*/}
      {/*  </p>*/}
      {/*  <p>{backtests.length} backtests</p>*/}
      {/*</header>*/}


      <h2 className={'title'}>Backtest Runs</h2>
      <div className="c-activity-stream-list">

        {/*  /!*<div>*!/*/}
        {/*    /!*<H2 text={'Backtests'}/>*!/*/}
        {/*    /!*<p>Backtests</p>*!/*/}
        {/*  /!*</div>*!/*/}

        {!isFetchingBacktests && backtests.length === 0 ? (
          <p>
            No Backtests that match your filters. Check back soon.
          </p>
        ) : (
          <>
            <InfiniteScroll
              pageStart={0}
              loadMore={handleInfiniteScroll}
              hasMore={!atEnd}
            >
              {isFetchingBacktests ? (
                <ActivityIndicator />
              ) : (
                <Table columns={columns} data={backtests}/>
              )}
            </InfiniteScroll>
          </>
        )}

        {isInfiniteScrolling && <ActivityIndicator />}
      </div>

    </div>

  )
}
