import {createSlice} from "@reduxjs/toolkit";
import {IBar} from "../../models/interfaces/i-bar";
import {fetchBarsForPosition} from "../thunks/fetch-bars-for-position";
import {fetchBarsForChart} from "../thunks/fetch-bars-for-chart";


interface BarsState {
  barsByPosition: {[key: number]: Array<IBar>}
  barsByChartId: {[key: string]: Array<IBar>}
  isFetchingBarsForPosition: {[key: number]: boolean}
  isFetchingBarsForChartId: {[key: string]: boolean}
  fetchErrorForPosition: {[key: number]: any}
  fetchErrorChartId: {[key: string]: any}
}


const initialState: BarsState = {
  barsByPosition: {},
  isFetchingBarsForPosition: {},
  barsByChartId: {},
  isFetchingBarsForChartId: {},
  fetchErrorForPosition: {},
  fetchErrorChartId: {}
}

export const barsSlice = createSlice({
  name: 'bars',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(fetchBarsForPosition.pending, (state, action) => {
      state.isFetchingBarsForPosition[action.meta.arg.positionId] = true;
      state.fetchErrorForPosition[action.meta.arg.positionId] = undefined;
    })
    builder.addCase(fetchBarsForPosition.fulfilled, (state, action) => {
      state.barsByPosition[action.meta.arg.positionId] = action.payload;
      state.isFetchingBarsForPosition[action.meta.arg.positionId] = false;
    })
    builder.addCase(fetchBarsForPosition.rejected, (state, action) => {
      state.isFetchingBarsForPosition[action.meta.arg.positionId] = false;
      state.fetchErrorForPosition[action.meta.arg.positionId] = action.error;
    })
    builder.addCase(fetchBarsForChart.pending, (state, action) => {
      state.isFetchingBarsForChartId[action.meta.arg.chartId] = true;
      state.fetchErrorChartId[action.meta.arg.chartId] = undefined;
    })
    builder.addCase(fetchBarsForChart.fulfilled, (state, action) => {
      state.barsByChartId[action.meta.arg.chartId] = action.payload;
      state.isFetchingBarsForChartId[action.meta.arg.chartId] = false;
    })
    builder.addCase(fetchBarsForChart.rejected, (state, action) => {
      state.isFetchingBarsForChartId[action.meta.arg.chartId] = false;
      state.fetchErrorChartId[action.meta.arg.chartId] = action.error;
    })

  }
});
