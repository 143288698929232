import {combineReducers} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useSelector} from "react-redux";
import {navSlice} from "./slices/nav";
import {backtestSlice} from "./slices/backtest";
import {barsSlice} from "./slices/bars";
import {setupsSlice} from "./slices/setups";


const rootReducer = combineReducers({
  backtest: backtestSlice.reducer,
  bars: barsSlice.reducer,
  nav: navSlice.reducer,
  setups: setupsSlice.reducer
});


export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
