import {createAsyncThunk} from "@reduxjs/toolkit";
import {ApiManager} from "../../managers/api-manager";

interface DeleteSetupParams {
  setupId: number
  setupInstanceId: number
}

export const deleteSetupInstanceThunk = createAsyncThunk(
  'backtest/deleteBacktest',
  async (params: DeleteSetupParams, {dispatch, getState}) => {
    console.log('deleteBacktest');
    await ApiManager.deleteSetupInstance(params.setupId, params.setupInstanceId);
    return params.setupInstanceId;
  }
)
