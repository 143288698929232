import {useTypedSelector} from "../../../store/reducers";
import React, {useEffect, useState} from "react";
import ActivityIndicator from "../../../components/ActivityIndicator";
import {fetchBacktest} from "../../../store/thunks/fetch-backtest-thunk";
import {useParams} from "react-router";
import {useAppDispatch} from "../../../store";
import Table from "../../../components/Table";
import {Link} from "react-router-dom";
import {IPosition} from "../../../models/interfaces/i-position";
import {formatCurrency, formatDate, formatPercent} from "../../../util/formatting";
import {EquityCurveChart} from "../../../components/charts/EquityCurveChart";
import {BarsUtil} from "../../../util/bars-util";
import Button from "../../../components/Button";

import './Backtest.scss'

interface IBacktestRouteParams {
  backtestId: string
}

export const Backtest = () => {

  const dispatch = useAppDispatch();

  let { backtestId } = useParams<IBacktestRouteParams>();
  const bt = useTypedSelector(state => state.backtest.detailedBacktests[parseInt(backtestId)])
  const isFetchingBacktest = useTypedSelector(state => state.backtest.isFetchingBacktest[parseInt(backtestId)])
  const errorFetchingBacktest = useTypedSelector(state => state.backtest.errorFetchingBacktest[parseInt(backtestId)])

  const [showSource, setShowSource] = useState(false);

  // const [spyData, setSpyData] = useState<Array<IOhlcData> | null>(null);
  //
  // useEffect(() => {
  //   const fetchSpyData = async(start: Date, end: Date) => {
  //     const data = await ApiManager.fetchBars('SPY', BarType.Daily, start, end);
  //     return BarsUtil.convertBarsToLineOhlc(data);
  //   };
  //
  //   if (!isFetchingBacktest && bt && !spyData) {
  //     fetchSpyData(bt.start, bt.end)
  //     .then(data => {
  //       setSpyData(data);
  //     }).catch(err => {
  //       console.error('error fetching spy data: ', err);
  //     })
  //   }
  // }, [bt, isFetchingBacktest]);

  useEffect(() => {

    if (!bt && !isFetchingBacktest && !errorFetchingBacktest) {
      dispatch(fetchBacktest(parseInt(backtestId)));
    }
  }, [bt, isFetchingBacktest, errorFetchingBacktest, dispatch, backtestId])


  type PositionRow = {row: {original: IPosition}}

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({row: {original}}: PositionRow) => {
          return (
            <Link to={'/backtests/' + backtestId + '/positions/' + original.id}>Trade {original.id}</Link>
          );
        }
      },
      {
        Header: 'Win/Loss',
        accessor: 'isWin',
        Cell: ({row: {original}}: PositionRow) => {
          return (
            <p>{original.isWin ? 'W' : 'L'}</p>
          );
        }
      },
      {
        Header: 'P&L',
        accessor: 'pnl',
        Cell: ({row: {original}}: PositionRow) => {
          return (
            <p>{formatCurrency(original.realizedGain)} ({formatPercent(original.realizedGain / original.basis)})</p>
          );
        }
      },
      {
        Header: 'Ticker',
        accessor: 'ticker',
        Cell: ({row: {original}}: PositionRow) => {
          return (
            <p>{original.ticker}</p>
          );
        }
      }
    ]
  }, []); // eslint-disable-line

  return (
    <>
      {isFetchingBacktest ? (
        <ActivityIndicator/>
      ) : (
        <>
          {bt ? (
            <div className={'backtest-wrapper'}>
              <h2 className={'title'}>Backtest: {bt.id}</h2>

              <h3>Stats</h3>

              <ul>
                <li>{'Percent Return: ' + formatPercent(bt.stats.cumulativeReturn) + ' (' + formatCurrency(bt.stats.startValue) + ' -> ' + formatCurrency(bt.stats.endValue) + ')'}</li>
                <li>Win Rate: {formatPercent(bt.stats.winRate)}</li>
                <li>Sharpe Ratio: {bt.stats.sharpeRatio.toFixed(4)}</li>
                <li>Max Drawdown: {formatPercent(bt.stats.maxDrawdown)}</li>
                <li>Gain to Pain: {bt.stats.gainToPain.toFixed(4)}</li>
                <li>BPS outperform SPY: {(bt.stats.percentChangeAboveSpy * 100).toFixed(0)}</li>
              </ul>

              <EquityCurveChart data={BarsUtil.convertEquityCurveToOhlc(bt.equityCurve)} height={200} width={400} />

              <h3>Configuration</h3>

              <ul>
                <li>Time Range Days: {bt.stats.timeRangeDays} ({formatDate(bt.start)} - {formatDate(bt.end)})</li>
                <li>Tickers: {bt.tickers.join(', ')}</li>
              </ul>

              <h3>Source Code</h3>

              <div>
                <Button size={'small'} onClick={() => setShowSource(!showSource)}>
                  {showSource ? 'Hide Source' : 'Show Source'}
                </Button>
              </div>

              {showSource && (
                <div className={'source-code'}>
                  <pre>
                    {bt.strategySource}
                  </pre>
                </div>
              )}

              <h3>Trades</h3>


              <Table columns={columns} data={bt.positions}/>

            </div>
          ) : (
            <div className={'backtest-wrapper'}>
              <p>Failed to load backtest</p>
            </div>
          )}
        </>
      )}
    </>
  )
}
