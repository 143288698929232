import React from 'react';
import './App.css';
import {LeftNav} from "../leftnav/LeftNav";
import {About} from "../about/About";
import {Redirect, Switch} from "react-router";
import {Route} from "react-router-dom";
import {Backtests} from "../backtests/Backtests";
import {Home} from "../home/Home";
import {Setups} from "../setups/Setups";

export const App = () => {
  // console.log('App()');
  // const {currentScreen} = useTypedSelector(state => state.nav);


  // useEffect(() => {
    // console.log('App hook');
  // })

  return (
    <div className="App">

      <LeftNav/>

      <Switch>
        <Route path="/about" exact component={About} />
        <Route path="/backtest" component={Backtests} />
        <Route path="/home" component={Home} />
        <Route path="/setups" component={Setups} />
        <Route path="/" exact >
          <Redirect to="/home" />
        </Route>
      </Switch>

    </div>
  );
}

export default App;
