
import './Position.scss'
import {useParams} from "react-router";
import {SinglePositionChart} from "../../components/charts/SinglePositionChart";
import {useTypedSelector} from "../../store/reducers";
import {useAppDispatch} from "../../store";
import {fetchBarsForPosition} from "../../store/thunks/fetch-bars-for-position";
import {fetchBacktest} from "../../store/thunks/fetch-backtest-thunk";
import ActivityIndicator from "../../components/ActivityIndicator";
import {BarsUtil} from "../../util/bars-util";
import {useEffect, useState} from "react";
import {BarType} from "../../models/enums/bar-type";
import {renderPrice, renderTradeTimestamp} from "../../util/formatting";
import moment from "moment";

interface IPositionRouteParams {
  backtestId: string
  positionId: string
}

export const Position = () => {

  let { positionId, backtestId } = useParams<IPositionRouteParams>();
  const dispatch = useAppDispatch();

  const parsedPositionId = parseInt(positionId);
  const parsedBacktestId = parseInt(backtestId);

  const [didAttemptFetch, setDidAttemptFetch] = useState(false);

  const isFetchingBarsForPosition = useTypedSelector(state => state.bars.isFetchingBarsForPosition[parsedPositionId]);
  const positionBars = useTypedSelector(state => state.bars.barsByPosition[parsedPositionId]);
  const backtest = useTypedSelector(state => state.backtest.detailedBacktests[parsedBacktestId])
  const isFetchingBacktest = useTypedSelector(state => state.backtest.isFetchingBacktest[parsedBacktestId]);

  const position = backtest ? backtest.positions.find(p => p.id === parsedPositionId) : undefined;

  useEffect(() => {
    if (!backtest && !isFetchingBacktest) {
      console.log('fetching backtest for position component: ', backtestId);
      dispatch(fetchBacktest(parsedBacktestId));
    }
  }, [backtest, isFetchingBacktest]) // eslint-disable-line

  useEffect(() => {
    if (position) {

      if (!positionBars && !isFetchingBarsForPosition && !didAttemptFetch) {
        console.log('fetching position bars');
        dispatch(fetchBarsForPosition({
          positionId: parsedPositionId,
          ticker: position.ticker,
          start: moment(position.entryTimestamp).subtract(20, 'day').toDate(),
          end: position.exitTimestamp ? moment(position.exitTimestamp).add(1, 'day').toDate() : undefined,
          type: BarType.Daily
        }))
        console.log('dispatch fetch');
        setDidAttemptFetch(true)
      }
    }
  }, [position, positionBars, isFetchingBarsForPosition, didAttemptFetch]) // eslint-disable-line



  return isFetchingBacktest || isFetchingBarsForPosition || !positionBars || !position ? (
    <ActivityIndicator />
  ) : (
    <div className={'position-wrapper'}>
      <h2 className={'title'}>Position: {positionId}</h2>
      <ul>
        <li>Ticker: {position.ticker}</li>
        <li>Hold Length: {position.holdLength}</li>
        <li>Enter: {renderTradeTimestamp(position.entryTimestamp)} @ {renderPrice(position.entryPrice)}</li>
        <li>Exit: {renderTradeTimestamp(position.exitTimestamp)} @ {renderPrice(position.exitPrice)}</li>
        <li>Profit: {renderPrice(position.realizedGain)}</li>
      </ul>

      <SinglePositionChart
        chartId={'position_' + position.id}
        ticker={position.ticker}
        height={300}
        width={600}
        ratio={1}
        entryTimestamp={new Date(position.entryTimestamp)}
        entryPrice={position.entryPrice}
        exitTimestamp={new Date(position.exitTimestamp)}
        exitPrice={position.exitPrice}
        data={BarsUtil.convertBarsToOhlc(positionBars)}/>
      {/*<Daily/>*/}

    </div>
  )

}
