import * as React from "react";
import { Chart, ChartCanvas } from "@react-financial-charts/core";
import { XAxis, YAxis } from "@react-financial-charts/axes";
// import {discontinuousTimeScaleProvider} from "@react-financial-charts/scales";
import { CandlestickSeries } from "@react-financial-charts/series";
import {
  Annotate,
  CrossHairCursor,
  discontinuousTimeScaleProviderBuilder, EdgeIndicator,
  Label, MouseCoordinateX, MouseCoordinateY,
  SvgPathAnnotation
} from "react-financial-charts";
import {IOhlcData} from "../../models/interfaces/i-ohlc-data";
import './Charts.scss'
import {renderTradeTimestamp} from "../../util/formatting";
import moment from "moment";
import {format} from "d3-format";
import {timeFormat} from "d3-time-format";

interface ChartProps {
  readonly data: IOhlcData[]
  readonly chartId: string
  readonly height: number
  readonly width: number
  readonly ratio: number
  readonly ticker: string
  readonly entryPrice?: number
  readonly exitPrice?: number
  readonly entryTimestamp: Date
  readonly exitTimestamp: Date
}

export const SinglePositionChart = (props: ChartProps) =>  {


  const margin = { left: 20, right: 40, top: 10, bottom: 30 };
  const xScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
    (d: IOhlcData) => d.date,
  );

  const { data: initialData, height, ratio, width } = props;

  const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(initialData);

  const max = xAccessor(data[data.length - 1]);
  const min = xAccessor(data[Math.max(0, data.length - 100)]);
  const xExtents = [min, max];

  const ChartAny = Chart as any;


  const yExtents = (data: IOhlcData) => {
    return [data.high, data.low];
  };


  console.log('SinglePositionChart: ', props.chartId, data);
  // console.log('SinglePositionChart: ', data, height, ratio, width, margin, displayXAccessor, xScale, xAccessor, xExtents);

  // console.log('SinglePositionChart: ', data);

  const svgAnnotation = (tooltip: string) => ({
    fill: "#2196f3",
    path: () =>
      "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
    pathWidth: 12,
    pathHeight: 22,
    tooltip,
    y: ({ yScale, datum }: any) => yScale(datum.high),
  });

  const redOrGreen = (isGreen: boolean) => {
    return isGreen ? "#26a69a" : "#ef5350";
  };

  const dateTimeFormat = "%d %b";
  const pricesDisplayFormat = format(",.2f");
  const timeDisplayFormat = timeFormat(dateTimeFormat);

  // console.log('rendering position chart: ', props.data);

  return (
    <div className={'chart-wrapper'}>
      <ChartCanvas
        height={height}
        ratio={ratio}
        width={width}
        margin={margin}
        data={data}
        displayXAccessor={displayXAccessor}
        seriesName="Data"
        xScale={xScale}
        xAccessor={xAccessor}
        xExtents={xExtents}
      >
        <ChartAny id={1} yExtents={yExtents}>
          <CandlestickSeries />
          <XAxis showGridLines />
          <YAxis showGridLines />

          {/*<OHLCTooltip origin={[8, 16]} textFill={(d) => (d.close > d.open ? "#26a69a" : "#ef5350")} />*/}

          <Label
            text={props.ticker}
            x={(width - margin.left - margin.right) / 2}
            y={(height - margin.top - margin.bottom) / 2}
          />

          <CrossHairCursor />
          <MouseCoordinateX displayFormat={timeDisplayFormat} />
          <MouseCoordinateY rectWidth={margin.right} displayFormat={pricesDisplayFormat} />



          { props.entryPrice && props.exitPrice && (

            <>
              <EdgeIndicator
                itemType="last"
                rectWidth={margin.right}
                fill={'#999999'}
                lineStroke={'#999999'}
                displayFormat={pricesDisplayFormat}
                yAccessor={(data: IOhlcData) => props.entryPrice}
              />

              <EdgeIndicator
                itemType="last"
                rectWidth={margin.right}
                fill={() => redOrGreen(props.exitPrice! > props.entryPrice!)}
                lineStroke={redOrGreen(props.exitPrice > props.entryPrice)}
                displayFormat={pricesDisplayFormat}
                yAccessor={(data: IOhlcData) => props.exitPrice}
              />
            </>
          )}



          <Annotate with={SvgPathAnnotation} usingProps={svgAnnotation('Enter: \n' + renderTradeTimestamp(props.entryTimestamp.toISOString()) + '\nPrice: ' + props.entryPrice)} when={(data: IOhlcData) => {
            // console.log('annotate: ', data.date, props.entryTimestamp, data.date.getTime() === props.entryTimestamp.getTime())
            return moment(data.date).startOf('day').toDate().getTime() === moment(props.entryTimestamp).startOf('day').toDate().getTime()
          }} />

          <Annotate with={SvgPathAnnotation} usingProps={svgAnnotation('Exit: \n' + renderTradeTimestamp(props.exitTimestamp.toISOString()) + '\nPrice: ' + props.exitPrice)} when={(data: IOhlcData) => {
            // console.log('annotate: ', data.date, props.exitTimestamp, data.date.getTime() === props.exitTimestamp!.getTime())

            return moment(data.date).startOf('day').toDate().getTime() === moment(props.exitTimestamp!).startOf('day').toDate().getTime()
          }} />


          </ChartAny>
      </ChartCanvas>
    </div>
  );

}

// export const Daily = withOHLCData()(withSize({ style: { minHeight: 600 } })(withDeviceRatio()(SinglePositionChart)));

// export const Intraday = withOHLCData("MINUTES")(
//   withSize({ style: { minHeight: 600 } })(withDeviceRatio()(BasicCandlestick)),
// );
