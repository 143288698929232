import {createAsyncThunk} from "@reduxjs/toolkit";
import {ApiManager} from "../../managers/api-manager";
import {BarType} from "../../models/enums/bar-type";

interface IFetchBarsForChartParams {
  chartId: string
  ticker: string
  start: Date
  end?: Date
  type: BarType
}

export const fetchBarsForChart = createAsyncThunk(
  'bars/fetchBarsForChart',
  async (params: IFetchBarsForChartParams, {dispatch, getState}) => {
    // console.log('going to fetch')
    const result = await ApiManager.fetchBars(params.ticker, params.type, params.start, params.end);
    return result;
  }
)
