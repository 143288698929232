import {useState} from "react";
import {LeftNavItem} from "./LeftNavItem";
import './LeftNav.scss'
import {H2} from "../../components/H2/H2";
import Button from "../../components/Button";
import {Icon} from "../../components/Icon/Icon";
import {useHistory} from "react-router";

export const LeftNav = () => {

  const [isExpanded, setIsExpanded] = useState(false);
  const history = useHistory();

  const didClickCollapse = () => {
    setIsExpanded(false)
  }

  const didClickExpand = () => {
    setIsExpanded(true)
  }

  const didClickNavItem = (location: string) => {
    history.push(location);
    setIsExpanded(false);
  }

  // const onClickNavLink = (screen: CurrentScreen) => {
  //   setIsExpanded(false)
  //   dispatch(navSlice.actions.setCurrentScreen(screen));
  // }

  return isExpanded ? (
    <div className={'left-nav-container'}>
      <div className={'left-nav-menu'}>
        <H2 text={'TradingTools'}/>

        <LeftNavItem icon={'vector-square'} title={'Home'} onClick={() => didClickNavItem('/')}/>
        <LeftNavItem icon={'book-bookmark'} title={'Setup Library'} onClick={() => didClickNavItem('/setups')}/>
        <LeftNavItem icon={'chart-line'} title={'Backtesting'} onClick={() => didClickNavItem('/backtest')}/>
        <LeftNavItem icon={'question'} title={'About'} onClick={() => didClickNavItem('/about')}/>


      </div>
      <div className={'left-nav-background'} onClick={() => didClickCollapse()}/>

    </div>

  ) : (
    <div className={'left-nav-hamburger-button'}>
      <Button size={'small'} onClick={() => didClickExpand()}>
        <Icon type={'bars'} size={'lg'}/>
        {/*<img src={"/hamburger_icon_64.png"} style={{ height: 25 }} alt={"menu"}/>*/}
      </Button>
    </div>
  )
}


