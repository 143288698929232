import {SinglePositionChart} from "./SinglePositionChart";
import {useTypedSelector} from "../../store/reducers";
import {BarsUtil} from "../../util/bars-util";
import ActivityIndicator from "../ActivityIndicator";
import {fetchBarsForChart} from "../../store/thunks/fetch-bars-for-chart";
import {useAppDispatch} from "../../store";
import {BarType} from "../../models/enums/bar-type";

interface ChartProps {
  // readonly data: IOhlcData[];
  readonly chartId: string
  readonly height: number
  readonly width: number
  readonly ratio: number
  readonly ticker: string
  // readonly entryPrice: number;
  // readonly exitPrice: number;
  readonly entryTimestamp: Date
  readonly exitTimestamp: Date
}

export const AsyncSinglePositionChart = (props: ChartProps) => {

  const dispatch = useAppDispatch();

  const bars = useTypedSelector(state => state.bars.barsByChartId[props.chartId]);
  const isLoadingBars = useTypedSelector(state => state.bars.isFetchingBarsForChartId[props.chartId]);
  const fetchError = useTypedSelector(state => state.bars.fetchErrorChartId[props.chartId]);

  if (!bars && !isLoadingBars && !fetchError) {
    console.log('Fetching bars for chart: ', props.chartId);
    dispatch(fetchBarsForChart({
      chartId: props.chartId,
      ticker: props.ticker,
      start: props.entryTimestamp,
      end: props.exitTimestamp,
      type: BarType.Daily
    }))
  }

  if (fetchError) {
    return (
      <>
        <p>Error</p>
      </>
    )
  }

  return (isLoadingBars || !bars) ? (
    <>
      <ActivityIndicator/>
    </>
    ) : (
    <>
      <SinglePositionChart
        chartId={props.chartId}
        data={BarsUtil.convertBarsToOhlc(bars)}
        height={props.height}
        width={props.width}
        ratio={props.ratio}
        ticker={props.ticker}
        entryTimestamp={props.entryTimestamp}
        exitTimestamp={props.exitTimestamp}/>
    </>
  )
}
