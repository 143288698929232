import {createAsyncThunk} from "@reduxjs/toolkit";
import {ApiManager} from "../../managers/api-manager";


export const fetchSetups = createAsyncThunk(
  'setups/fetchSetups',
  async (_: void, {dispatch, getState}) => {

    const result = await ApiManager.fetchSetups();
    return result.items;
  }
)
