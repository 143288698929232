import axios from "axios";
import {Config} from "../util/config";
import {IBacktest} from "../models/interfaces/i-backtest";
import {IBar} from "../models/interfaces/i-bar";
import moment from "moment-timezone";
import {BarType} from "../models/enums/bar-type";
import {ISetup} from "../models/interfaces/i-setup";
import {IDraftSetupInstance} from "../models/interfaces/i-draft-setup-instance";
import {ISetupInstance} from "../models/interfaces/i-setup-instance";

export class ApiManager {

  public static async createSetupInstance(draft: IDraftSetupInstance): Promise<ISetupInstance> {
    const result = await this.post('/api/v1/trade_setup/' + draft.tradeSetupId + '/instance', draft);
    console.log('created setup instance: ', result);
    return result.data.data;
  }

  public static async fetchBacktests(): Promise<{items: Array<IBacktest>}> {
    const result = await this.get('/api/v1/backtest');
    console.log('fetched backtests: ', result);
    return result.data.data;
  }

  public static async fetchSetups(): Promise<{items: Array<ISetup>}> {
    const result = await this.get('/api/v1/trade_setup');
    console.log('fetched setups: ', result);
    return result.data.data;
  }

  public static async fetchSetupInstances(tradeSetupId: number, page: number): Promise<{items: Array<ISetupInstance>}> {
    const result = await this.get('/api/v1/trade_setup/' + tradeSetupId + '/instance?page=' + page);
    console.log('fetched setup instances: ', result);
    return result.data.data;
  }

  public static async fetchSetupById(tradeSetupId: number): Promise<ISetup> {
    const result = await this.get('/api/v1/trade_setup/' + tradeSetupId);
    console.log('fetched setup: ', result);
    return result.data.data;
  }

  public static async fetchBacktest(backtestId: number): Promise<IBacktest> {
    const result = await this.get('/api/v1/backtest/' + backtestId);
    console.log('fetched backtest ', backtestId, ': ', result);
    return result.data.data;
  }

  public static async deleteBacktest(backtestId: number): Promise<void> {
    await this.delete('/api/v1/backtest/' + backtestId);
  }

  public static async deleteSetupInstance(setupId: number, tradeInstanceId: number): Promise<void> {
    await this.delete('/api/v1/trade_setup/' + setupId + '/instance/' + tradeInstanceId);
  }

  public static async fetchBars(ticker: string, type: BarType, start: Date, end?: Date): Promise<Array<IBar>> {
    const query = this.buildQueryParams({
      ticker,
      type,
      start: start.toISOString(),
      end: end ? end.toISOString() : moment(start).add(1, 'month').toISOString()
    })
    const result = await this.get(`/api/v1/bars?${query}`)
    // console.log('fetched bars: ', result);

    // console.log('test1: ', moment('2020-10-19T04:00:00.000Z').toISOString(true))
    // console.log('test2: ', moment('2020-10-19T04:00:00.000Z').tz('America/New_York').toISOString(true))

    // const diff = moment('2020-10-19T04:00:00.000Z').diff(moment('2020-10-19T04:00:00.000Z').tz('America/New_York'), 'minute');
    // console.log('diff: ', diff);

    if (type === BarType.Daily) {
      // bars are in eastern time, so if we render them from a timezone less than eastern, they will be one day off
      // we use moment-timezone to snap them to local timezone
      result.data.data.forEach((bar: IBar) => {
        // console.log('processing bar: ', bar.timestamp, moment(bar.timestamp), moment(bar.timestamp).tz('America/New_York'));
        // const prev = moment(bar.timestamp);
        // const test = moment(bar.timestamp).tz('America/New_York');
        // bar.timestamp = moment(bar.timestamp).tz('America/New_York').toISOString(false) as any;
        // console.log('prev: ', prev, 'test: ', test, 'now: ', moment(bar.timestamp));
        bar.timestamp = moment(moment(bar.timestamp).tz('America/New_York').format('YYYY-MM-DD')).toDate().toISOString() as any
        // bar.timestamp = moment(bar.timestamp).add(60, 'minute').toISOString(true) as any
      })
    }


    // console.log('adjusted bars: ', result);
    return result.data.data;
  }

  private static async get(path: string) {
    // console.log('GET ', path);
    try {
      return await axios.get(`${Config.API_URL}${path}`);
    } catch (err) {
      console.log('error in request: ', err);
      throw err;
    }
  }

  private static async post(path: string, data: any) {
    // console.log('POST ', path);
    try {
      return await axios.post(`${Config.API_URL}${path}`, data);
    } catch (err) {
      console.log('error in request: ', err);
      throw err;
    }
  }

  private static async delete(path: string) {
    // console.log('DELETE ', path);
    try {
      return await axios.delete(`${Config.API_URL}${path}`);
    } catch (err) {
      console.log('error in request: ', err);
      throw err;
    }
  }

  private static buildQueryParams(params: {[key: string]: any}): string {
    // console.log('building: ', params);
    return Object.keys(params).filter(key => !!params[key]).map(key => `${key}=${params[key]}`).join('&');
  }
}
