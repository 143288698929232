import {createAsyncThunk} from "@reduxjs/toolkit";
import {ApiManager} from "../../managers/api-manager";

interface IFetchInstanceParams {
  tradeSetupId: number
  page: number
}

export const fetchSetupInstances = createAsyncThunk(
  'setups/fetchSetupInstances',
  async (params: IFetchInstanceParams, {dispatch, getState}) => {

    console.log('fetching setup instances for setup: ', params.tradeSetupId, 'page: ', params.page);
    const result = await ApiManager.fetchSetupInstances(params.tradeSetupId, params.page);
    return result.items;
  }
)
