import {createAsyncThunk} from "@reduxjs/toolkit";
import {ApiManager} from "../../managers/api-manager";
import {IDraftSetupInstance} from "../../models/interfaces/i-draft-setup-instance";


export const createSetupInstance = createAsyncThunk(
  'setups/createSetupInstance',
  async (draft: IDraftSetupInstance, {dispatch, getState}) => {

    const result = await ApiManager.createSetupInstance(draft);
    return result;
  }
)
