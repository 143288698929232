import {createSlice} from "@reduxjs/toolkit";
import {fetchBacktests} from "../thunks/fetch-backtests-thunk";
import {fetchBacktest} from "../thunks/fetch-backtest-thunk";
import {IBacktest} from "../../models/interfaces/i-backtest";
import {deleteBacktestThunk} from "../thunks/delete-backtest-thunk";


interface BacktestState {
  backtests: Array<IBacktest>
  detailedBacktests: {[key: number]: IBacktest}
  isFetchingBacktest: {[key: number]: boolean}
  errorFetchingBacktest: {[key: number]: any}
  isFetchingBacktests: boolean
}


const initialState: BacktestState = {
  backtests: [],
  detailedBacktests: {},
  isFetchingBacktest: {},
  errorFetchingBacktest: {},
  isFetchingBacktests: false
}

export const backtestSlice = createSlice({
  name: 'backtest',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(fetchBacktests.pending, (state, action) => {
      state.isFetchingBacktests = true;
    })
    builder.addCase(fetchBacktests.fulfilled, (state, action) => {
      state.backtests = action.payload;
      state.isFetchingBacktests = false;
    })
    builder.addCase(fetchBacktests.rejected, (state, action) => {
      state.isFetchingBacktests = false;
    })
    builder.addCase(fetchBacktest.pending, (state, action) => {
      state.isFetchingBacktest[action.meta.arg] = true;
    })
    builder.addCase(fetchBacktest.fulfilled, (state, action) => {
      state.detailedBacktests[action.payload.id] = action.payload;
      state.isFetchingBacktest[action.meta.arg] = false;
    })
    builder.addCase(fetchBacktest.rejected, (state, action) => {
      // console.log('error fetching backtest: ', action);
      state.isFetchingBacktest[action.meta.arg] = false;
      state.errorFetchingBacktest[action.meta.arg] = action.error;
    })
    builder.addCase(deleteBacktestThunk.pending, (state, action) => {
      state.backtests = state.backtests.filter(backtest => backtest.id !== action.meta.arg);
    })
  }
});
