import './SetupsListing.scss'
import {useTypedSelector} from "../../../store/reducers";
import React, {useEffect, useState} from "react";
import {fetchSetups} from "../../../store/thunks/fetch-setups-thunk";
import {useAppDispatch} from "../../../store";
import {ISetup} from "../../../models/interfaces/i-setup";
import {Link} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import ActivityIndicator from "../../../components/ActivityIndicator";
import Table from "../../../components/Table";

export const SetupsListing = () => {

  const dispatch = useAppDispatch();

  const {setups, isFetchingSetups} = useTypedSelector(state => state.setups);
  const [atEnd, setAtEnd] = useState(true);
  const [isInfiniteScrolling, setIsInfiniteScrolling] = useState(false);


  useEffect(() => {
    const initialize = async () => {
      if(!isFetchingSetups) {
        dispatch(fetchSetups());
      } else {
        setAtEnd(false);
      }
    };
    initialize();
  }, []); // eslint-disable-line

  const handleInfiniteScroll = async () => {
    console.log('handleInfiniteScroll');
    if(isFetchingSetups || isInfiniteScrolling || atEnd) {
      return;
    }

    try {
      setIsInfiniteScrolling(true);
      let res = await dispatch(fetchSetups());
      setTimeout(() => {
        console.log('res: ', res);
        setAtEnd(true);
        setIsInfiniteScrolling(false);
      }, 200);

    } catch(err) {
      console.log('handleInfiniteScroll err', err);
      setAtEnd(true);
      setIsInfiniteScrolling(false);
    }
  };

  type SetupRow = {row: {original: ISetup}}

  const columns = React.useMemo(() => {
      return [
        {
          Header: 'Name',
          accessor: 'name',
          Cell: ({row: {original}}: SetupRow) => {
            return (
              <Link to={'/setups/' + original.id}>{original.name}</Link>
            );
          }
        }
      ]
    }, []
  )

  return (
    <div className={'setups-listing-wrapper'}>

      <h2 className={'title'}>Setup Library</h2>

      <div className="setup-list">

        <div className="c-activity-stream-list">

          {!isFetchingSetups && setups.length === 0 ? (
            <p>
              No Setups that match your filters. Check back soon.
            </p>
          ) : (
            <>
              <InfiniteScroll
                pageStart={0}
                loadMore={handleInfiniteScroll}
                hasMore={!atEnd}
              >
                {isFetchingSetups ? (
                  <ActivityIndicator />
                ) : (
                  <Table columns={columns} data={setups}/>
                )}
              </InfiniteScroll>
            </>
          )}

          {isInfiniteScrolling && <ActivityIndicator />}
        </div>

      </div>

    </div>
  )
}
