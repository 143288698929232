import moment from "moment";

export const renderDate = (date: Date) => {
  let todayDay = moment().format('L');
  let yesterdayDay = moment().subtract('1', 'days').format('L');
  let dateDay = moment(date).format('L');

  if(todayDay === dateDay) {
    return `Today at ${moment(date).format('h:mm a')}`;
  } else if(yesterdayDay === dateDay) {
    return `Yesterday at ${moment(date).format('h:mm a')}`;
  } else {
    return `${dateDay} at ${moment(date).format('h:mm a')}`;
  }
};

export const renderTradeTimestamp = (isoTimestamp?: string) => {
  if (!isoTimestamp) {
    return '--'
  }
  const time = new Date(isoTimestamp);
  return moment(time).format('YYYY-MM-DD hh:mm:ss')
}

export const renderPrice = (amount?: number) => {
  if (amount) {
    return `$${amount.toFixed(2)}`
  } else {
    return amount;
  }
}

export const formatCurrency = (amount: number) => {
  return `$ ${Number(amount.toFixed(2)).toLocaleString()}`
}

export const formatPercent = (ratio: number) => {
  return `${(ratio * 100).toFixed(2)}%`
}

export const formatDate = (value: Date) => {
  return moment(value).format('MMM DD, YYYY')
}
