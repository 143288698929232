import * as React from "react";
import { Chart, ChartCanvas } from "@react-financial-charts/core";
import { XAxis, YAxis } from "@react-financial-charts/axes";
import {
  CrossHairCursor,
  discontinuousTimeScaleProviderBuilder, EdgeIndicator,
  LineSeries, MouseCoordinateX, MouseCoordinateY,
} from "react-financial-charts";
import {IOhlcData} from "../../models/interfaces/i-ohlc-data";
import './Charts.scss'
import {format} from "d3-format";
import {timeFormat} from "d3-time-format";

interface ChartProps {
  readonly data: IOhlcData[];
  readonly spyData?: IOhlcData[];
  readonly height: number;
  readonly width: number;
  // readonly ratio: number;
  // readonly ticker: string;
  // readonly entryPrice: number;
  readonly exitPrice?: number;
  // readonly entryTimestamp: Date;
  readonly exitTimestamp?: Date;
}

export const EquityCurveChart = (props: ChartProps) =>  {


  const margin = { left: 20, right: 80, top: 10, bottom: 30 };
  const xScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
    (d: IOhlcData) => d.date,
  );

  const { data: initialData, height, width } = props;

  const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(initialData);

  const max = xAccessor(data[data.length - 1]) + 20;
  const min = 20;
  // const min = xAccessor(data[Math.max(0, data.length - 100)]);
  const xExtents = [min, max];

  console.log('xExtents: ', xExtents);

  const ChartAny = Chart as any;


  const yExtents = (data: IOhlcData) => {
    // console.log('yExtents: ', data);
    return [data.high, 0];
    // return [data.high, data.low];
  };


  // console.log('EquityCurveChart: ', data);

  const yAccessor = (data: IOhlcData) => {
    return data.close;
  }

  const openCloseColor = (data: IOhlcData) => {
    return data.close > props.data[0].open ? "#26a69a" : "#ef5350";
  };

  // const openCloseColor = (data: IOhlcData) => {
  //   return data.close > data.open ? "#26a69a" : "#ef5350";
  // };

  const yEdgeIndicator = (data: IOhlcData) => {
    return data.close;
  };

  const dateTimeFormat = "%d %b";
  const pricesDisplayFormat = format(",.2f");
  const timeDisplayFormat = timeFormat(dateTimeFormat);

  // const compareCalculator = compare()
  // .options({
  //   basePath: "close",
  //   mainKeys: ["open", "high", "low", "close"],
  //   compareKeys: ["spy"],
  // })
  // .accessor((d: any) => d.compare)
  // .merge((d: any, c: any) => {
  //   d.compare = c;
  // });

  return (
    <div className={'chart-wrapper'}>
      <p>Equity Curve</p>
      <ChartCanvas
        height={height}
        ratio={4}
        width={width}
        margin={margin}
        data={data}
        displayXAccessor={displayXAccessor}
        // postCalculator={compareCalculator}
        seriesName="Data"
        xScale={xScale}
        xAccessor={xAccessor}
        xExtents={xExtents}
      >
        <ChartAny id={1} yExtents={yExtents}>

          <LineSeries yAccessor={d => d.close} />
          {/*<LineSeries yAccessor={(d) => {*/}
          {/*  console.log('accessor1: ', d.close);*/}
          {/*  return d.high*/}
          {/*}} strokeStyle="#2ca02c" />*/}
          <LineSeries yAccessor={(d) => {
            // const value = d.close;
            const spyValue = d.spy;

            // console.log('accessor value: ', value, spyValue);
            return spyValue;
            // return d.spy * 1000
          }} strokeStyle="#FAC898" />

          {props.spyData && (
            <LineSeries yAccessor={yAccessor} />
          )}

          <XAxis showGridLines />
          <YAxis showGridLines />

          {/*<OHLCTooltip origin={[8, 16]} textFill={(d) => (d.close > d.open ? "#26a69a" : "#ef5350")} />*/}

          <CrossHairCursor snapX={true} />


          <MouseCoordinateX displayFormat={timeDisplayFormat} />
          <MouseCoordinateY rectWidth={margin.right} displayFormat={pricesDisplayFormat} />

          <EdgeIndicator
            itemType="last"
            rectWidth={margin.right}
            fill={openCloseColor}
            lineStroke={openCloseColor}
            displayFormat={pricesDisplayFormat}
            yAccessor={yEdgeIndicator}
          />

          </ChartAny>
      </ChartCanvas>
    </div>
  );

}

// export const Daily = withOHLCData()(withSize({ style: { minHeight: 600 } })(withDeviceRatio()(SinglePositionChart)));

// export const Intraday = withOHLCData("MINUTES")(
//   withSize({ style: { minHeight: 600 } })(withDeviceRatio()(BasicCandlestick)),
// );
