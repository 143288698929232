
interface ITextboxProps {
    onChange: (value: string) => void
}

export const Textbox = (props: ITextboxProps) => {


    return (
      <>
        <input
          onChange={(event) => props.onChange(event.target.value)}
        />
      </>
    )
}
