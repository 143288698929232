import {createAsyncThunk} from "@reduxjs/toolkit";
import {ApiManager} from "../../managers/api-manager";
import {BarType} from "../../models/enums/bar-type";

interface IFetchBarsForPositionParams {
  positionId: number
  ticker: string
  start: Date
  end?: Date
  type: BarType
}

export const fetchBarsForPosition = createAsyncThunk(
  'bars/fetchBarsForPosition',
  async (params: IFetchBarsForPositionParams, {dispatch, getState}) => {
    // console.log('going to fetch')
    const result = await ApiManager.fetchBars(params.ticker, params.type, params.start, params.end);
    return result;
  }
)
