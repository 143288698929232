import './ActivityIndicator.scss';

import React from 'react';
import PropTypes from 'prop-types';

const ActivityIndicator = ({color}) => (
    <div className="c-activity-indicator">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ margin: "auto", width: '100%', height: '100%' }}
            display="block"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 100 100"
        >
            <circle
                cx="50"
                cy="50"
                r="32"
                fill="none"
                stroke={color}
                strokeDasharray="50.26548245743669 50.26548245743669"
                strokeLinecap="round"
                strokeWidth="8"
                transform="rotate(318.801 50 50)"
            >
                <animateTransform
                    attributeName="transform"
                    dur="1s"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    type="rotate"
                    values="0 50 50;360 50 50"
                />
            </circle>
        </svg>
    </div>
);

ActivityIndicator.defaultProps = {
    color: 'red'
};

ActivityIndicator.propTypes = {
    color: PropTypes.string,
};

export default ActivityIndicator;
