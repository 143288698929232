// import styled from "styled-components";
import React from "react";
//@ts-ignore
// import Tooltip from "react-simple-tooltip"
// import Spacer from "../../components/Spacer";
// import {UrlGen} from "../../util/url-gen";
import "./About.scss"

export const About = () => {

  // console.log('About.render');

  return (
    <>
      <div className={'about-title'}>
        <p>Backtester 1.0</p>
      </div>
    </>
  )
}

