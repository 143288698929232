import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CurrentScreen} from "../../models/enums/current-screen";

interface NavState {
  currentScreen: CurrentScreen;
}

const initialState: NavState = {
  currentScreen: CurrentScreen.Home
}

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setCurrentScreen: (state, action: PayloadAction<CurrentScreen>) => {
      state.currentScreen = action.payload;
    }
  }
});
