import {createAsyncThunk} from "@reduxjs/toolkit";
import {ApiManager} from "../../managers/api-manager";


export const fetchDetailedSetup = createAsyncThunk(
  'setups/fetchDetailedSetup',
  async (tradeSetupId: number, {dispatch, getState}) => {

    const result = await ApiManager.fetchSetupById(tradeSetupId);
    return result;
  }
)
