import {IBar} from "../models/interfaces/i-bar";
import {IOhlcData} from "../models/interfaces/i-ohlc-data";
import {IBacktestTimeSeries} from "../models/interfaces/i-backtest-time-series";

export class BarsUtil {
  public static convertBarsToOhlc(bars: Array<IBar>): Array<IOhlcData> {
    return bars.map(bar => ({
      ...bar,
      date: new Date(bar.timestamp)
    }))
  }

  public static convertBarsToLineOhlc(bars: Array<IBar>): Array<IOhlcData> {
    return bars.map(bar => ({
      date: new Date(bar.timestamp),
      open: bar.close,
      high: bar.close,
      low: bar.close,
      close: bar.close,
      volume: 0
    }))
  }

  public static convertEquityCurveToOhlc(equityCurve: Array<IBacktestTimeSeries>): Array<IOhlcData> {
    return equityCurve.map(row => ({
      date: new Date(row.t),
      open: row.v,
      high: row.v,
      low: row.v,
      close: row.v,
      spy: row.spy,
      volume: 0
    }))
  }
}
