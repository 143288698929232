import './Setup.scss';
import {Textbox} from "../../../components/Textbox";
import Button from "../../../components/Button";
import {useState} from "react";
import {fetchDetailedSetup} from "../../../store/thunks/fetch-detailed-setup-thunk";
import {useParams} from "react-router";
import {useTypedSelector} from "../../../store/reducers";
import {useAppDispatch} from "../../../store";
import ActivityIndicator from "../../../components/ActivityIndicator";
import {SetupInstances} from "./SetupInstances";
import {createSetupInstance} from "../../../store/thunks/create-setup-instance-thunk";

interface IDetailedSetupRouteParams {
  setupId: string
}

export const Setup = () => {

  const dispatch = useAppDispatch();

  let { setupId } = useParams<IDetailedSetupRouteParams>();

  const {detailedSetup, isFetchingDetailedSetup, detailedSetupFetchError, isPostingSetupInstance} = useTypedSelector(state => state.setups);

  const [quickEntryTicker, setQuickEntryTicker] = useState('');
  const [quickEntryStart, setQuickEntryStart] = useState('');
  const [quickEntryEnd, setQuickEntryEnd] = useState('');

  const didClickQuickEntrySubmit = () => {
    console.log('Submitting quick entry: ', quickEntryTicker, quickEntryStart, quickEntryEnd);
    dispatch(createSetupInstance({
      tradeSetupId: parseInt(setupId),
      symbol: quickEntryTicker,
      start: new Date(quickEntryStart),
      end: new Date(quickEntryEnd)
    }))
  }

  if (!detailedSetup && !isFetchingDetailedSetup && !detailedSetupFetchError) {
    dispatch(fetchDetailedSetup(parseInt(setupId)));
  }

  if (detailedSetupFetchError) {
    return (
      <>
        <h2 className={'title'}>Error fetching Setup {setupId}</h2>
      </>
    )
  }

  return detailedSetup ? (

    <div className={'setup-wrapper'}>

      <h2 className={'title'}>Setup: {detailedSetup.name}</h2>

      <div className={'setup-quick-entry-wrapper'}>

        <div className={'setup-quick-entry'}>
          <p>Quick Entry:</p>
          <p>Ticker:</p>
          <Textbox onChange={value => setQuickEntryTicker(value)}/>
          <p>Start:</p>
          <Textbox onChange={value => setQuickEntryStart(value)}/>
          <p>End:</p>
          <Textbox onChange={value => setQuickEntryEnd(value)}/>
          <p></p><p></p>
          <Button onClick={() => didClickQuickEntrySubmit()} disabled={isPostingSetupInstance}>Submit</Button>
        </div>
      </div>

      <SetupInstances tradeSetupId={parseInt(setupId)} />

    </div>
  ) : (
    <>
      <ActivityIndicator/>
    </>
  )

}
