import React, {useEffect, useState} from "react";
import {ISetupInstance} from "../../../models/interfaces/i-setup-instance";
import InfiniteScroll from "react-infinite-scroller";
import {useTypedSelector} from "../../../store/reducers";
import {useAppDispatch} from "../../../store";
import {fetchSetupInstances} from "../../../store/thunks/fetch-setup-instances-thunk";
import ActivityIndicator from "../../../components/ActivityIndicator";
import Table from "../../../components/Table";
import Button from "../../../components/Button";
import {Icon} from "../../../components/Icon/Icon";
import {deleteSetupInstanceThunk} from "../../../store/thunks/delete-setup-instance-thunk";
import {AsyncSinglePositionChart} from "../../../components/charts/AsyncSinglePositionChart";

interface SetupInstancesParams {
  tradeSetupId: number
}

export const SetupInstances = (params: SetupInstancesParams) => {

  const dispatch = useAppDispatch();

  const {setupInstances, isFetchingSetupInstances} = useTypedSelector(state => state.setups);
  const [atEnd, setAtEnd] = useState(false);
  const [isInfiniteScrolling, setIsInfiniteScrolling] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      setIsInfiniteScrolling(true);
      await dispatch(fetchSetupInstances({tradeSetupId: params.tradeSetupId, page: 0}));
      setIsInfiniteScrolling(false);
      console.log('initial load complete');
    };
    initialize();
  }, []); // eslint-disable-line

  const handleInfiniteScroll = async (page: any) => {
    console.log('handleInfiniteScroll:', page);
    if(atEnd) {
      console.log('skipping infinite scroll: ', isFetchingSetupInstances, atEnd);
      return;
    }

    try {
      setIsInfiniteScrolling(true);
      const res: any = await dispatch(fetchSetupInstances({tradeSetupId: params.tradeSetupId, page}));
      if (res.payload.length === 0) {
        console.log('no results, setting infinite scroll end')
        setAtEnd(true);
        setIsInfiniteScrolling(false);
      } else {
        console.log('loaded page ', page);
      }
      // setTimeout(() => {
      //   console.log('res: ', res);
      //   setAtEnd(true);
      //   setIsInfiniteScrolling(false);
      // }, 200);

    } catch(err) {
      console.log('handleInfiniteScroll err', err);
      setAtEnd(true);
      setIsInfiniteScrolling(false);
    }
  };

  type SetupInstanceRow = {row: {original: ISetupInstance}}

  const columns = React.useMemo(() => {
      return [
        {
          Header: 'ID',
          accessor: 'id',
          Cell: ({row: {original}}: SetupInstanceRow) => {
            return (
              <><p>{original.id}</p></>
            );
          }
        },
        {
          Header: 'Chart',
          accessor: 'chart',
          Cell: ({row: {original}}: SetupInstanceRow) => {
            return (
              <AsyncSinglePositionChart chartId={original.id + '_instance_row'} height={150} width={300} ratio={1} ticker={original.symbol} entryTimestamp={new Date(original.start)} exitTimestamp={new Date(original.end)}/>
            )
          }
        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
          Cell: ({row: {original}}: SetupInstanceRow) => {
            return (
              <><p>Instance: {original.symbol}</p></>
            );
          }
        },
        {
          Header: 'Start',
          accessor: 'start',
          Cell: ({row: {original}}: SetupInstanceRow) => {
            return (
              <><p>{original.start}</p></>
            );
          }
        },
        {
          Header: 'End',
          accessor: 'end',
          Cell: ({row: {original}}: SetupInstanceRow) => {
            return (
              <><p>{original.end}</p></>
            );
          }
        },
        {
          Header: 'Actions',
          accessor: 'actions',
          Cell: ({row: {original}}: SetupInstanceRow) => {
            return (
              <>
                <Button size={"small"} onClick={() => dispatch(deleteSetupInstanceThunk({setupId: params.tradeSetupId, setupInstanceId: original.id}))}>
                  <Icon type={'trash'} size={'lg'}/>
                </Button>
              </>
            )
          }
        }
      ]
    }, [dispatch] // eslint-disable-line
  )

  return (
    <div className={'setups-listing-wrapper'}>

      <h3 className={'title'}>Setup Instances</h3>

      <div className="setup-list">

        <div>

          {!isFetchingSetupInstances && setupInstances.length === 0 ? (
            <p>
              No Instances that match your filters. Check back soon.
            </p>
          ) : (
            <>
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleInfiniteScroll}
                hasMore={!atEnd && !isInfiniteScrolling}
                loader={<ActivityIndicator key={'spinner'} />}
              >
                {/*{isFetchingSetupInstances ? (*/}
                {/*  <ActivityIndicator />*/}
                {/*) : (*/}
                  <Table columns={columns} data={setupInstances}/>
                {/*)}*/}
              </InfiniteScroll>
            </>
          )}

          {/*{isInfiniteScrolling && <ActivityIndicator />}*/}
        </div>

      </div>

    </div>
  )
}
